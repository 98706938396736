import { useState, useEffect, useRef, useCallback } from 'react';

const useInterval = (callback, time, delay) => {
	const intervalRef = useRef();

	const stop = useCallback(() => clearInterval(intervalRef.current), []);

	const start = useCallback(delay => {
		const _start = () => {
			intervalRef.current = setInterval(callback, time);
		}

		stop();

		if (+delay > 0) {
			setTimeout(_start, delay);
		} else {
			_start();
		}
	}, []);

	useEffect(() => {
		start(delay);

		return stop;
	}, []);

	return { intervalRef, start, stop }
}

// Components
const GalleryImages = ({ images, imgIndex, wrapper, container, loadCount }) => <div className='galleryImages' ref={container}>
	{images?.length && images.map((image, i) => {
		const imageVisible = imgIndex || 0;

		return <img key={i} className={imageVisible === i ? 'imageVisible' : ''} src={image}
			onLoad={() => {
				loadCount.current++

				if (loadCount.current === images.length) {
					container.current?.classList.add('galleryImagesLoaded');
					wrapper.current?.querySelector('.galleryLoader').classList.remove('visible');
				}
			}}
		/>
	})}
</div>

const GalleryLoader = () => <span className='galleryLoader visible'>
	<span className='bar'></span>
	<span className='bar'></span>
	<span className='bar'></span>
	<span className='bar'></span>
	<span className='bar'></span>
</span>;

const GalleryHeader = ({ title, subtitle, onClick }) => <header className='galleryHeader'>
	<div className='galleryHeadings'>
		<h3>{title}</h3>
		<h5>{subtitle}</h5>
	</div>

	{onClick && <button className='btnClose' onClick={onClick} >
		<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='currentColor'>
			<path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z' />
		</svg>
	</button>}
</header>;


const Gallery = ({ gallery, slideSpeed, columns, isPopup = true, editActiveIndex = null, setEditActiveIndex = null }) => {
	// Popup Placeholder
	const popupGallery = useRef(null);
	const popupGalleryItem = useRef(null);
	const popupImgContainer = useRef();
	const popupImgLoadCount = useRef(0);
	const popupTargetOffset = useRef([0, 0]);
	// const popupCancelAnimationTimeout = useRef();
	// const popupHover = useRef(false);
	const activeItemImgLength = useRef(0);

	const [activeIndex, setActiveIndex] = useState(null);
	const [popupImgIndex, setPopupImgIndex] = useState(0);

	const popupItem = gallery[activeIndex];
	activeItemImgLength.current = popupItem?.images.length;

	// const { start: startPopupAnimation, stop: stopPopupAnimation } = useAnimationFrame(() => animate(popupHover, popupTargetOffset, popupImgContainer));

	const cycleImgIndex = useCallback((popupInterval = false, direction = 'forward', reset = false) => {
		reset && popupInterval();

		setPopupImgIndex(currentIndex => {
			const newIndex = direction === 'forward' ? currentIndex + 1 : currentIndex - 1;

			return newIndex > activeItemImgLength.current - 1 ? 0 :
				newIndex < 0 ? activeItemImgLength.current - 1 : newIndex
		});
	}, []);

	const selectImgIndex = useCallback((popupInterval, newIndex, reset = false) => {
		reset && popupInterval();

		setPopupImgIndex(newIndex);
	}, []);

	const { start: startPopupInterval } = useInterval(() => cycleImgIndex(), slideSpeed * 1000, 0);

	const onClickGalleryItem = useCallback((index) => {
		setActiveIndex(index);
		setEditActiveIndex && setEditActiveIndex(index);

		isPopup && popupGallery.current?.classList.add('active');
	}, []);

	const onClickClose = useCallback(() => {
		setTimeout(() => {
			setActiveIndex(null);
		}, 750);
		popupGallery.current?.classList.remove('active');
		popupImgLoadCount.current = 0;
	}, []);

	useEffect(() => {
		popupTargetOffset.current = [0, 0];
	}, []);

	return <>
		<div className={`galleryItems columns-${columns.desktop} columns-tablet-${columns.tablet} columns-mobile-${columns.mobile}`}>
			{gallery.map((item, index) => {
				const { title, subtitle, images } = item;

				const galleryItem = useRef(null);
				const imgContainer = useRef();
				const targetOffset = useRef([0, 0]);
				// const cancelAnimationTimeout = useRef();
				const imgLoadCount = useRef(0);
				// const hover = useRef(false);

				let imgIndex = 0;
				const cycleImgIndexInner = useCallback(() => {
					const newIndex = imgIndex + 1;
					imgIndex = newIndex > images.length - 1 ? 0
						: newIndex < 0 ? images.length - 1
							: newIndex;

					imgContainer.current?.childNodes?.forEach(el => {
						el && el.classList.contains('imageVisible') && el.classList.remove('imageVisible')
					});
					imgContainer.current?.childNodes[imgIndex].classList.add('imageVisible');
				}, []);

				// const { start: startAnimation, stop: stopAnimation } = useAnimationFrame(() => animate(hover, targetOffset, imgContainer));

				useInterval(() => images.length > 1 && cycleImgIndexInner(), slideSpeed * 1000, index * 200);

				useEffect(() => {
					targetOffset.current = [0, 0];
				}, []);

				return <div key={index} className={`galleryItem ${null !== editActiveIndex && index === editActiveIndex ? 'bigbNowEditing' : ''}`} id={`galleryItem-${index}`} onClick={() => onClickGalleryItem(index)}
					// onMouseEnter={() => onMouseEnter(startAnimation, cancelAnimationTimeout, hover)}
					// onMouseMove={e => onMouseMove(e, galleryItem.current)}
					// onMouseLeave={() => onMouseLeave(stopAnimation, cancelAnimationTimeout, hover)}
					ref={galleryItem}>

					<GalleryImages images={images} wrapper={galleryItem} container={imgContainer} loadCount={imgLoadCount} />

					<GalleryLoader />

					<GalleryHeader title={title} subtitle={subtitle} />
				</div>;
			})}
		</div>

		{isPopup && <div className='popupGallery' ref={popupGallery}>
			<div className='popupContent'>
				{popupItem && <div className={`galleryItem ${null !== editActiveIndex && activeIndex === editActiveIndex ? 'nowEditing' : ''}`} id={`galleryItem-${activeIndex}`}
					// onMouseEnter={() => onMouseEnter(startPopupAnimation, popupCancelAnimationTimeout, popupHover)}
					// onMouseMove={e => onMouseMove(e, popupGalleryItem.current)}
					// onMouseLeave={() => onMouseLeave(stopPopupAnimation, popupCancelAnimationTimeout, popupHover)}
					ref={popupGalleryItem}>

					<GalleryImages images={popupItem.images} imgIndex={popupImgIndex} wrapper={popupGalleryItem} container={popupImgContainer} loadCount={popupImgLoadCount} />

					<GalleryLoader />

					<GalleryHeader title={popupItem.title} subtitle={popupItem.subtitle} onClick={onClickClose} />

					{popupItem.images.length > 1 && <footer className='galleryFooter'>
						<nav className='controls'>
							<button className='control controlArrow' onClick={() => cycleImgIndex(startPopupInterval, 'backward', true)}>
								<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='currentColor'>
									<path d='M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z' />
								</svg>
							</button>
							{popupItem.images.map((_, i) => (
								<button key={i}
									className={`control controlDot ${i === popupImgIndex ? 'controlDotActive' : ''}`}
									data-index={i}
									onClick={() => selectImgIndex(startPopupInterval, i)}
								></button>
							))}
							<button className='control controlArrow' onClick={() => cycleImgIndex(startPopupInterval, 'forward', true)}>
								<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='currentColor'>
									<path d='M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z' />
								</svg>
							</button>
						</nav>
					</footer>}
				</div>}
			</div>
		</div>}
	</>
}
export default Gallery;