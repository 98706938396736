import { createRoot } from 'react-dom/client';

import './style.scss';
import Style from './Components/Common/Style';
import Gallery from './Components/Common/Gallery';

document.addEventListener('DOMContentLoaded', () => {
	const allImageGallery = document.querySelectorAll('.wp-block-bigb-image-gallery');
	allImageGallery.forEach(imageGallery => {
		const attributes = JSON.parse(imageGallery.dataset.attributes);
		const { cId, gallery, slideSpeed, columns } = attributes;

		createRoot(imageGallery).render(<>
			<Style attributes={attributes} clientId={cId} />

			<div className='bigbImageGallery'>
				<Gallery gallery={gallery} slideSpeed={slideSpeed} columns={columns} />
			</div>
		</>);

		imageGallery?.removeAttribute('data-attributes');
	});
});