import { getBackgroundCSS, getShadowCSS, getTypoCSS } from '../../../../Components/utils/getCSS';

const Style = ({ attributes, clientId }) => {
	const { gallery, itemHeight, columnGap, rowGap, background, shadow, hoverShadow, titleTypo, subtitleTypo } = attributes;

	const gallerySl = `#bigbImageGallery-${clientId} .bigbImageGallery`;

	return <>
		<style dangerouslySetInnerHTML={{
			__html: `
			${getTypoCSS('', titleTypo)?.googleFontLink}
			${getTypoCSS('', subtitleTypo)?.googleFontLink}
			${getTypoCSS(`${gallerySl} .galleryHeader h3`, titleTypo)?.styles}
			${getTypoCSS(`${gallerySl} .galleryHeader h5`, subtitleTypo)?.styles}

			${gallerySl} .galleryItems{
				grid-gap: ${rowGap} ${columnGap};
			}
			${gallerySl} .galleryItem{
				height: ${itemHeight};
				${getBackgroundCSS(background)}
				box-shadow: ${getShadowCSS(shadow)};
			}
			${gallerySl} .galleryItem:hover,
			${gallerySl} .galleryItem.nowEditing{
				box-shadow: ${getShadowCSS(hoverShadow)};
			}
			`.replace(/\s+/g, ' ')
		}} />

		<style>
			{gallery?.map((item, index) => {
				const { colors, btnColors } = item;

				const itemHeaderSl = `${gallerySl} #galleryItem-${index} .galleryHeader`;
				const itemFooterSl = `${gallerySl} #galleryItem-${index} .galleryFooter`;

				const btnColor = btnColors.color;
				const btnBG = 'gradient' === btnColors.bgType ? btnColors.gradient : btnColors.bg;

				return `
					${itemHeaderSl}::before,
					${itemFooterSl}::before{
						background: ${'gradient' === colors.bgType ? colors.gradient : colors.bg};
					}
					${itemHeaderSl} h3,
					${itemHeaderSl} h5{
						color: ${colors.color};
					}

					${itemHeaderSl} .btnClose{
						color: ${btnColor};
						background: ${btnBG};
					}
					${itemFooterSl} nav.controls .control{
						background: ${btnBG};
					}
					${itemFooterSl} nav.controls .controlDot{
						background: ${btnBG};
					}
					${itemFooterSl} nav.controls .controlDot::after{
						background: ${btnColor};
					}
					${itemFooterSl} nav.controls .controlArrow{
						color: ${btnColor};
					}
				`.replace(/\s+/g, ' ');
			})}
		</style>
	</>;
}
export default Style;